import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 101,
        label: 'MENUITEMS.ABSTRACT.LIST.CRYPTO',
        icon: 'bx bxl-bitcoin',
        link: '/dashboards/crypto',
    },
    {
        id: 102,
        label: 'MENUITEMS.ABSTRACT.LIST.INDEX',
        icon: 'bx bx-trending-up',
        link: '/404',
    },
    {
        id: 103,
        label: 'MENUITEMS.ABSTRACT.LIST.STOCK',
        icon: 'bx bx-line-chart',
        link: '/404',
    },

    {
        id: 200,
        label: 'MENUITEMS.CRYPTO.TEXT',
        isTitle: true
    },
    {
        id: 23,
        label: 'MENUITEMS.APPS.TEXT',
        icon: 'bx-customize',
        subItems: [
            {
                id: 24,
                label: 'MENUITEMS.CALENDAR.TEXT',
                link: '/calendar',
            },
            {
                id: 25,
                label: 'MENUITEMS.CHAT.TEXT',
                link: '/chat',
            },
            {
                id: 26,
                label: 'MENUITEMS.EMAIL.TEXT',
                subItems: [
                    {
                        id: 27,
                        label: 'MENUITEMS.EMAIL.LIST.INBOX',
                        link: '/email/inbox',
                        parentId: 26
                    },
                    {
                        id: 28,
                        label: 'MENUITEMS.EMAIL.LIST.READEMAIL',
                        link: '/email/read',
                        parentId: 26
                    }
                ]
            },
            {
                id: 29,
                label: 'MENUITEMS.ECOMMERCE.TEXT',
                subItems: [
                    {
                        id: 30,
                        label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTS',
                        link: '/ecommerce/products',
                        parentId: 29
                    },
                    {
                        id: 31,
                        label: 'MENUITEMS.ECOMMERCE.LIST.PRODUCTDETAIL',
                        link: '/ecommerce/product-detail/:id',
                        parentId: 29
                    },
                    {
                        id: 32,
                        label: 'MENUITEMS.ECOMMERCE.LIST.ORDERS',
                        link: '/ecommerce/orders',
                        parentId: 29
                    },
                    {
                        id: 33,
                        label: 'MENUITEMS.ECOMMERCE.LIST.CUSTOMERS',
                        link: '/ecommerce/customers',
                        parentId: 29
                    },
                    {
                        id: 34,
                        label: 'MENUITEMS.ECOMMERCE.LIST.CART',
                        link: '/ecommerce/cart',
                        parentId: 29
                    },
                    {
                        id: 35,
                        label: 'MENUITEMS.ECOMMERCE.LIST.CHECKOUT',
                        link: '/ecommerce/checkout',
                        parentId: 29
                    },
                    {
                        id: 36,
                        label: 'MENUITEMS.ECOMMERCE.LIST.SHOPS',
                        link: '/ecommerce/shops',
                        parentId: 29
                    },
                    {
                        id: 37,
                        label: 'MENUITEMS.ECOMMERCE.LIST.ADDPRODUCT',
                        link: '/ecommerce/add-product',
                        parentId: 29
                    },
                ]
            },
            {
                id: 38,
                label: 'MENUITEMS.CRYPTO.TEXT',
                icon: 'bx-bitcoin',
                subItems: [
                    {
                        id: 39,
                        label: 'MENUITEMS.CRYPTO.LIST.WALLET',
                        link: '/crypto/wallet',
                        parentId: 38
                    },
                    {
                        id: 40,
                        label: 'MENUITEMS.CRYPTO.LIST.BUY/SELL',
                        link: '/crypto/buy-sell',
                        parentId: 38
                    },
                    {
                        id: 41,
                        label: 'MENUITEMS.CRYPTO.LIST.EXCHANGE',
                        link: '/crypto/exchange',
                        parentId: 38
                    },
                    {
                        id: 42,
                        label: 'MENUITEMS.CRYPTO.LIST.LENDING',
                        link: '/crypto/lending',
                        parentId: 38
                    },
                    {
                        id: 43,
                        label: 'MENUITEMS.CRYPTO.LIST.ORDERS',
                        link: '/crypto/orders',
                        parentId: 38
                    },
                    {
                        id: 44,
                        label: 'MENUITEMS.CRYPTO.LIST.KYCAPPLICATION',
                        link: '/crypto/kyc-application',
                        parentId: 38
                    },
                    {
                        id: 45,
                        label: 'MENUITEMS.CRYPTO.LIST.ICOLANDING',
                        link: '/crypto-ico-landing',
                        parentId: 38
                    }
                ]
            },
            {
                id: 46,
                label: 'MENUITEMS.PROJECTS.TEXT',
                subItems: [
                    {
                        id: 47,
                        label: 'MENUITEMS.PROJECTS.LIST.GRID',
                        link: '/projects/grid',
                        parentId: 46
                    },
                    {
                        id: 48,
                        label: 'MENUITEMS.PROJECTS.LIST.PROJECTLIST',
                        link: '/projects/list',
                        parentId: 46
                    },
                    {
                        id: 49,
                        label: 'MENUITEMS.PROJECTS.LIST.OVERVIEW',
                        link: '/projects/overview',
                        parentId: 46
                    },
                    {
                        id: 50,
                        label: 'MENUITEMS.PROJECTS.LIST.CREATE',
                        link: '/projects/create',
                        parentId: 46
                    }
                ]
            },
            {
                id: 51,
                label: 'MENUITEMS.TASKS.TEXT',
                subItems: [
                    {
                        id: 52,
                        label: 'MENUITEMS.TASKS.LIST.TASKLIST',
                        link: '/tasks/list',
                        parentId: 51
                    },
                    {
                        id: 53,
                        label: 'MENUITEMS.TASKS.LIST.KANBAN',
                        link: '/tasks/kanban',
                        parentId: 51
                    },
                    {
                        id: 54,
                        label: 'MENUITEMS.TASKS.LIST.CREATETASK',
                        link: '/tasks/create',
                        parentId: 51
                    }
                ]
            },
            {
                id: 55,
                label: 'MENUITEMS.CONTACTS.TEXT',
                icon: 'bxs-user-detail',
                subItems: [
                    {
                        id: 56,
                        label: 'MENUITEMS.CONTACTS.LIST.USERGRID',
                        link: '/contacts/grid',
                        parentId: 55
                    },
                    {
                        id: 57,
                        label: 'MENUITEMS.CONTACTS.LIST.USERLIST',
                        link: '/contacts/list',
                        parentId: 55
                    },
                    {
                        id: 58,
                        label: 'MENUITEMS.CONTACTS.LIST.PROFILE',
                        link: '/contacts/profile',
                        parentId: 55
                    }
                ]
            },
        ]
    },
    {
        id: 220,
        label: 'MENUITEMS.CRYPTO.LIST.MANAGEMENT',
        icon: 'bx bx-check-square',
        link: '/crypto/management',
    },
    {
        id: 221,
        label: 'MENUITEMS.CRYPTO.LIST.NOTE',
        icon: 'bx bx-edit',
        link: '/crypto/note',
    },

    {
        id: 3000,
        label: 'MENUITEMS.SYSTEM.TEXT',
        isTitle: true
    },

    {
        id: 3001,
        isLayout: true
    },
    {
        id: 3002,
        label: 'MENUITEMS.SYSTEM.LIST.LOG',
        icon: 'bx bx-notepad',
        link: '/system/log',
    },
    {
        id: 3003,
        label: 'MENUITEMS.SYSTEM.LIST.TASK',
        icon: 'bx bx-terminal',
        link: '/system/task',
    },
    
];

