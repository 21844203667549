import { TokenAuthenticationService } from './../core/services/token-auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DaemonService {

  constructor(
    private tokenAuthenticationService: TokenAuthenticationService
  ) { }

  init(){
    this.tokenAuthenticationService.init();
  }
}
