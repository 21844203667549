import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { Login } from './../models/http/login';
import { environment } from './../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class TokenAuthenticationService {

  userToken;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
  ) {
  }

  init() {
    if (this.storageService.isExist('token')) {
      console.log('set token');
      const token = this.storageService.getItem('token');
      this.userToken = token;
    } else {
      console.log('token not exist');
    }
  }

  public currentUser(): User {
    return this.userToken;
  }

  login(email: string, password: string) {
    const url = environment.apiUrl + '/auth/login'
    var payload = {};
    payload['account'] = email;
    payload['password'] = password;
    return this.http.post<Login>(url, payload);
  }

  logout() {
    // logout the user
    this.userToken = '';
  }
}


