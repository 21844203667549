import { GlobalConfig } from 'ngx-toastr';

export const toastConfig: Partial<GlobalConfig> = {
    timeOut: 2000,
    positionClass: 'toast-bottom-center',
    preventDuplicates: true,
}

export const shortToast: Partial<GlobalConfig> = {
    timeOut: 500
}