import { Component , OnInit} from '@angular/core';
import { DaemonService } from './init/daemon.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  constructor(
    private daemonService: DaemonService,
  ){}

  ngOnInit(){
    this.daemonService.init();
  }
}
