import { HttpHeaderInterceptor } from './http-header.interceptor';
import { HTTP_INTERCEPTORS } from "@angular/common/http";

// order is matter: 
// req: A->B->C
// res: C->B->A
export const interceptorProviders =
    [
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },

        //  { provide: HTTP_INTERCEPTORS, useClass: EncryptInterceptor, multi: true },  
    ];