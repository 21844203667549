import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

  // {
  //     id: 401,
  //     label: 'MENUITEMS.CRYPTO.LIST.INSIGHT',
  //     icon: 'bx bx-show',
  //     link: '/crypto/insight',
  // },
  
  // {
  //   id: 223,
  //   label: 'MENUITEMS.CRYPTO.LIST.TRADE_PLAN',
  //   icon: 'bx bx-edit',
  //   link: '/crypto/trade-plan',
  // },
  // {
  //   id: 400,
  //   label: 'MENUITEMS.CRYPTO.LIST.INSIGHT.TEXT',
  //   icon: 'bx bx-zoom-in',
  //   subItems: [
  //     {
  //       id: 410,
  //       label: 'MENUITEMS.CRYPTO.LIST.INSIGHT.LIST.BREAK_AREA',
  //       link: '/crypto/insight/break_area',
  //       parentId: 400
  //     }
  //   ]
  // },
  {
    id: 221,
    label: 'MENUITEMS.CRYPTO.LIST.NOTE',
    icon: 'bx bx-edit',
    link: '/crypto/note',
  },
  {
    id: 222,
    label: 'MENUITEMS.CRYPTO.LIST.CAP_CAL',
    icon: 'bx bx-edit',
    link: '/crypto/cap-calculator',
  },
  {
    id: 302,
    label: 'MENUITEMS.SYSTEM.LIST.LOG',
    icon: 'bx bx-notepad',
    link: '/system/log',
  },
  {
    id: 303,
    label: 'MENUITEMS.SYSTEM.LIST.TASK',
    icon: 'bx bx-terminal',
    link: '/system/task',
  },
  {
    id: 304,
    label: 'MENUITEMS.SYSTEM.LIST.DATA_MARKET',
    icon: 'bx bx-terminal',
    link: '/system/data-market',
  },
  
  {
    id: 2000,
    label: 'MENUITEMS.CRYPTO.LIST.FX',
    icon: 'bx bx-terminal',
    link: '/crypto/fx',
  },
];

