import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log('intercept');
    
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ad30262d-167b-491f-ab10-e040fb9dc656`
      }
    });

    console.log('url: ' + req.url);
    console.log(req);

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(req);
  }
}
